/* eslint-disable camelcase */
import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import styled, { css } from "styled-components";
import Markdown from "markdown-to-jsx";
import { PopupButton } from "@typeform/embed-react";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import GaugeChart from "./components/GaugeChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import {
  compactCurrencyNotation,
  currencyNotation,
  numberNotation,
  percentNotation
} from "../../utils/numberFormatters";
import GrossYield from "./components/GrossYield";
import { Colors } from "../../components/GlobalStyle";
import Star from "../../icons/Star";
import Header from "../../components/Header";
import fetcher from "../../utils/fetch";
import AnalyticsModal from "./components/AnalyticsModal";
import LiveMarkets from "../../liveMarkets";
import SkeletonLoader from "../../components/SkeletonLoader";

export interface Amenities {
  "Home Theater": string;
  "Free Parking": string;
  Sauna: string;
  Pool: string;
  Gym: string;
  "Hot Tub": string;
  "Pet Friendly": string;
  Balcony: string;
}

export interface Hosts {
  host_name: string;
  total_listings: string;
  avg_rating: number;
  city: string;
  data_of_analysis: string;
}

export interface RateData {
  market: number;
  Studio: number;
  "1 Bedrooms": number;
  "2 Bedrooms": number;
  "3 Bedrooms": number;
  "4 Bedrooms": number;
  "5 Bedrooms+": number;
}

export interface MarketData {
  market: string;
  updated: string;
  annual_revenue: number;
  annual_revenue_max: number;
  supply: RateData;
  average_daily_rate: RateData;
  property_tax: number;
  "airbnb-regulation": string;
  occupancy_rate: RateData;
  gross_yield: string;
  us_gross_yield_ranking: string;
  unemployment_rate: Record<string, string>;
  zillow_home_value_data: Record<string, number>;
  host_info: Hosts[];
  most_impactful_amenities: Amenities;
  demand_source: Record<string, string>;
  row_one_text: Record<string, string>;
  row_two_text: Record<string, string>;
  row_three_text: Record<string, string>;
}

const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 85vw;
  margin: 0 auto;

  h2 {
    color: var(--tertiary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--tertiary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }
  }
`;

const AnalyticRow = styled(Flex)<{ $reverse?: boolean }>`
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin: 0 2rem;
  padding: 1rem;

  ${(props) =>
    props.$reverse &&
    css`
      flex-direction: row-reverse;
    `};

  &:not(:last-child) {
    border-bottom: 1px solid var(--quaternary);
  }

  @media (max-width: 415px) {
    flex-direction: column;
    margin: 0;
  }
`;

const AnalyticsTitle = styled(Flex)`
  border-bottom: 1px solid var(--quaternary);
  justify-content: flex-end;
  flex: 1 1 100%;
  gap: 1rem;
  margin: 0 2rem;
  padding: 1rem;

  .analytics-title-updated,
  .analytics-title-market,
  .analytics-title-description,
  .analytics-beta {
    font-weight: 500;
  }

  .analytics-title-updated,
  .analytics-title-market {
    color: var(--quaternary);
  }
  .analytics-beta {
    color: var(--white);
    background: var(--quinary);
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    margin-left: -0.75rem;
  }

  .analytics-title-description {
    color: var(--tertiary);
    position: relative;

    &:before,
    &:after {
      background-color: var(--tertiary);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:before {
      left: -0.5rem;
    }

    &:after {
      right: -0.5rem;
    }
  }

  @media (max-width: 720px) {
    justify-content: center;
    margin: 0;
  }
`;

const AnalyticSection = styled(Flex)<{
  $column?: boolean;
  $halfColumn?: boolean;
  $third?: boolean;
  $verticalSplit?: boolean;
}>`
  align-items: flex-start;
  flex: 1 1 100%;
  text-align: left;

  ${(props) =>
    props.$third &&
    css`
      flex: 1 1 150%;
    `};

  ${(props) =>
    props.$halfColumn &&
    css`
      flex: 1 1 50%;
    `};

  ${(props) =>
    props.$column &&
    css`
      flex-direction: column;
    `};

  ${(props) =>
    props.$verticalSplit &&
    css`
      align-self: stretch;
      gap: 0.5rem;
    `};

  p {
    margin: 0 0 1rem;
  }

  @media (max-width: 815px) {
    flex: 1 1 calc(50% - 2rem);
  }

  @media (max-width: 410px) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const AnalyticsBox = styled(Flex)<{
  $decorative?: boolean;
  $knockout?: boolean;
  $knockoutBlue?: boolean;
  $knockout5?: boolean;
  $split?: boolean;
}>`
  border: 1px solid var(--quaternary);
  border-radius: 0.25rem;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0.5rem;
  position: relative;
  width: 100%;

  ${(props) =>
    props.$decorative &&
    css`
      background: url("/images/analytics_decorative.webp");
      background-size: cover;
      padding-top: 2rem;
    `}

  ${(props) =>
    props.$knockout &&
    css`
      background: var(--linearGradient);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockout5 &&
    css`
      background: var(--linearGradient5);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      background: var(--lightPurple);
      color: var(--tertiary);
    `}
`;

const AnalyticsBoxTitle = styled(Flex)<{
  $knockout?: boolean;
  $knockoutBlue?: boolean;
}>`
  border-bottom: 1px solid var(--quaternary);
  color: var(--tertiary);
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  padding: 0.25rem;
  width: 100%;

  ${(props) =>
    props.$knockout &&
    css`
      border-bottom: 1px solid var(--white);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      border-bottom: 1px solid var(--quaternary);
      color: var(--quaternary);
    `}

  .analytics-box-title-subtitle {
    font-weight: bold;
  }
`;

const AnalyticsSplitWrapper = styled(Flex)`
  align-items: stretch;
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 415px) {
    flex-direction: column;
  }
`;

const AnalyticsTextWrapper = styled(Flex)`
  align-items: flex-start;
  padding: 0 0.5rem;
  flex-direction: column;
  flex: 1 1 100%;

  strong {
    color: var(--quaternary);
  }
`;

const ChartWrapper = styled.div<{ $text?: boolean; $textBlue?: boolean }>`
  flex: 1 1 auto;
  position: relative;
  width: 100%;

  ${(props) =>
    (props.$text || props.$textBlue) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      padding: 1rem;
      line-height: 1;
      text-decoration: underline;
    `};

  ${(props) =>
    props.$text &&
    css`
      font-size: 2rem;
      color: var(--quaternary);
    `};
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default function AnalyticsMarket() {
  const { market } = useParams<Record<string, string>>();
  const [ratingHeight, setRatingHeight] = React.useState(0);
  const [ratingWrapperWidth, setRatingWrapperWidth] = React.useState(0);

  const urlSlug = React.useMemo(
    () =>
      market
        ? `https://www.getchalet.com/analytics/overview/${market}`
        : "https://www.getchalet.com/analytics/overview/",
    [market]
  );

  const { data, isLoading } = useSWR<MarketData>(
    `https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/market/${market}`,
    fetcher
  );

  let currentDate = new Date();
  let currentMonth = new Date();
  const hostData: Record<string, any> = {};

  if (data) {
    const splitUpdated = data.updated.split(" ");
    currentDate = new Date();
    currentDate.setMonth(
      months.findIndex((month) => month === splitUpdated[0])
    );
    currentDate.setFullYear(Number(splitUpdated[1]));
    currentMonth = new Date(currentDate.getTime());
    currentMonth.setMonth(currentMonth.getMonth() - 0);

    data.host_info.forEach(({ host_name, total_listings }) => {
      hostData[host_name] = total_listings;
    });
  }

  const setRef = React.useCallback((node) => {
    if (node) {
      const { height, width } = node.getBoundingClientRect();
      setRatingHeight(height);
      setRatingWrapperWidth(
        node.parentNode.getBoundingClientRect().width - width
      );
    }
  }, []);

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>{`${data?.market || "Market"} Analytics | Chalet`}</title>
        <meta
          name="description"
          content={`Airbnb Rental Market Analysis Update for ${
            data?.market || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <link rel="canonical" href={urlSlug} />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content={`${data?.market || "Market"} Analytics | Chalet`}
        />
        <meta
          property="og:description"
          content={`Airbnb Rental Market Update for ${
            data?.market || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://getchalet.com.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="og:site_name" content="Chalet" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${data?.market || "Market"} Analytics | Chalet`}
        />
        <meta
          name="twitter:description"
          content={`Airbnb Rental Market Update for ${
            data?.market || "the selected market"
          }. View Airbnb rental market data, including income potential, occupancy rates, and more.`}
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/og-image.jpg"
        />{" "}
        {/* Replace with actual image URL */}
      </Helmet>
      <AnalyticsHeader />
      {isLoading && (
        <AnalyticsWrapper marginTop={false}>
          <SkeletonLoader />
        </AnalyticsWrapper>
      )}
      {!isLoading && data === undefined && (
        <AnalyticsWrapper>
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            No data.
          </Flex>
        </AnalyticsWrapper>
      )}
      {!isLoading && data !== undefined && (
        <>
          <AnalyticsTitle>
            <span className="analytics-title-updated">{data.updated}</span>
            <span className="analytics-title-description">
              Short-Term Rental Market Update
            </span>
            <span className="analytics-title-market">{data.market}</span>
            {/* <span className="analytics-beta">Beta</span> */}
          </AnalyticsTitle>
          <AnalyticRow>
            <AnalyticSection $column $third>
              <AnalyticsTextWrapper>
                <Markdown>
                  {Object.values(data.row_one_text).join("\n\n")}
                </Markdown>
              </AnalyticsTextWrapper>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox $knockout>
                <AnalyticsBoxTitle $knockout>
                  Yearly Income Potential
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <GaugeChart
                    data={[data.annual_revenue, data.annual_revenue_max]}
                    title={[
                      "Annual Revenue",
                      currencyNotation(data.annual_revenue, 2)
                    ]}
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Total Active Short-Term Rentals</div>
                  <div className="analytics-box-title-subtitle">
                    {data.supply.market}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <PieChart data={data.supply as any} />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Average Daily Rate</div>
                  <div className="analytics-box-title-subtitle">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0
                    }).format(data.average_daily_rate.market as number)}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart data={data.average_daily_rate as any} yGrid xAxis />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
          </AnalyticRow>
          <AnalyticRow $reverse>
            <AnalyticSection $column $third>
              <AnalyticsTextWrapper>
                <Markdown>
                  {Object.values(data.row_two_text).join("\n\n")}
                </Markdown>
              </AnalyticsTextWrapper>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>
                    Occupancy Rate
                    {` (${
                      months[currentMonth.getMonth()]
                    } ${currentMonth.getFullYear()})`}
                  </div>
                  <div className="analytics-box-title-subtitle">
                    {percentNotation(data.occupancy_rate.market / 100)}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    data={data.occupancy_rate as any}
                    lighter
                    percent
                    yGrid
                    xAxis
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection $column $halfColumn $verticalSplit>
              <AnalyticsBox $split>
                <AnalyticsBoxTitle>Property Tax</AnalyticsBoxTitle>
                <ChartWrapper $text>
                  {percentNotation(data.property_tax, 2)}
                </ChartWrapper>
              </AnalyticsBox>
              <AnalyticsBox $split $knockoutBlue>
                <AnalyticsBoxTitle $knockoutBlue>
                  Airbnb Regulation
                </AnalyticsBoxTitle>
                <ChartWrapper $textBlue>
                  {data["airbnb-regulation"]
                    .split("-")
                    .map((str) => str[0].toUpperCase() + str.slice(1))
                    .join(" ")}
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>
                    Zillow Median Home Value{" "}
                    {`(${
                      months[currentMonth.getMonth()]
                    } ${currentMonth.getFullYear()})`}
                  </div>
                  <div className="analytics-box-title-subtitle">
                    {compactCurrencyNotation(
                      data?.zillow_home_value_data?.market || 0,
                      1
                    )}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  {data.zillow_home_value_data && (
                    <LineChart
                      data={data.zillow_home_value_data as any}
                      min={null}
                    />
                  )}
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
          </AnalyticRow>
          <AnalyticRow>
            <AnalyticSection $column $third>
              <AnalyticsTextWrapper>
                <Markdown>
                  {Object.values(data.row_three_text).join("\n\n")}
                </Markdown>
              </AnalyticsTextWrapper>
              <AnalyticsBox
                $decorative
                style={{
                  alignItems: "flex-start",
                  flex: "1 1 auto"
                }}
              >
                <Header
                  size={4}
                  style={{ alignItems: "flex-start", textAlign: "left" }}
                >
                  Real Estate Agents With
                  <br />
                  Airbnb Rental Investing Experience
                </Header>
                <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                  Let our agents guide you to{" "}
                  <strong style={{ color: "var(--quaternary)" }}>
                    the best short-term rental
                  </strong>{" "}
                  investments in {LiveMarkets[market]}
                </div>
                <div style={{ padding: "0 1rem" }}>
                  <StyledPopupButton
                    id="DU51pTsh"
                    // open="load"
                    // data-tf-lazy="false"
                    shareGaInstance="true"
                    hidden={{
                      market_of_interest:
                        LiveMarkets[market] || "Not Specified",
                      source_page: urlSlug
                    }}
                    className="connect-with-realtor_popup_top"
                  >
                    Connect with Airbnb Realtor
                  </StyledPopupButton>
                </div>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection $column $verticalSplit>
              <AnalyticsSplitWrapper>
                <AnalyticsBox $knockout5 $split>
                  <GrossYield
                    grossYield={data.gross_yield}
                    ranking={data.us_gross_yield_ranking}
                  />
                </AnalyticsBox>
                <AnalyticsBox $split $knockoutBlue>
                  <AnalyticsBoxTitle $knockoutBlue>
                    <div>Unemployment Rate</div>
                    <div className="analytics-box-title-subtitle">
                      <div>{data.unemployment_rate.market}</div>
                    </div>
                  </AnalyticsBoxTitle>
                  <ChartWrapper $textBlue>
                    <LineChart
                      color={Colors.tertiary}
                      data={data.unemployment_rate as any}
                      min={null}
                      y
                      percent
                    />
                  </ChartWrapper>
                </AnalyticsBox>
              </AnalyticsSplitWrapper>
              <Flex
                style={{
                  alignItems: "stretch",
                  gap: "0.5rem",
                  height: "100%",
                  width: "100%"
                }}
              >
                <AnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Most Impactful Amenities</div>
                    <div className="analytics-box-title-subtitle">
                      <div
                        style={{
                          background: "var(--seventh)",
                          color: "var(--white)",
                          padding: "0.25rem 0.5rem",
                          borderRadius: "0.25rem"
                        }}
                      >
                        Beta
                      </div>
                    </div>
                  </AnalyticsBoxTitle>
                  <ChartWrapper>
                    <BarChart
                      aspectRatio={1}
                      data={data.most_impactful_amenities as any}
                      horizontal
                      stacked
                      yAxisStyle={{
                        color: Colors.primary,
                        fontWeight: "normal"
                      }}
                    />
                  </ChartWrapper>
                </AnalyticsBox>
              </Flex>
            </AnalyticSection>
            <AnalyticSection $column $verticalSplit>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Host Info</div>
                  <div>Listings</div>
                  <div>Average Rating</div>
                </AnalyticsBoxTitle>
                <Flex style={{ width: "100%" }}>
                  <ChartWrapper>
                    {ratingHeight && (
                      <BarChart
                        sort="values"
                        height={ratingHeight}
                        width={ratingWrapperWidth}
                        data={hostData}
                        dataType="number"
                        color={Colors.tertiary}
                        datalabels={false}
                        horizontal
                        padding={{
                          top: 0,
                          bottom: 0
                        }}
                        yAxisStyle={{
                          color: Colors.primary,
                          fontWeight: "normal"
                        }}
                      />
                    )}
                  </ChartWrapper>
                  <Flex
                    style={{
                      flex: "1 1 30%",
                      flexDirection: "column",
                      padding: "0.5rem 0"
                    }}
                    ref={setRef}
                  >
                    {data.host_info.map(({ host_name, avg_rating }) => (
                      <Flex key={host_name}>
                        <Star
                          style={{
                            width: "1rem",
                            fill: "var(--linearGradient)"
                          }}
                        />
                        {numberNotation(avg_rating, 2)}
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </AnalyticsBox>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Source of Demand</div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    sort="values"
                    aspectRatio={1}
                    data={data.demand_source as any}
                    percent
                    horizontal
                    datalabels={false}
                    yAxis
                    yGrid
                    xAxisPosition="top"
                    yAxisStyle={{
                      color: Colors.primary,
                      fontWeight: "normal"
                    }}
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
          </AnalyticRow>
        </>
      )}
      {!isLoading && data !== undefined && (
        <FAQSection>
          <Header size={2}>Frequently Asked Questions</Header>
          <div className="faq-item">
            <Header size={3}>
              💰 What is the average income for Airbnb in {data?.market}?
            </Header>
            <Text>
              Based on our data, the average annual revenue for Airbnb
              properties in {data?.market} is{" "}
              {currencyNotation(data?.annual_revenue, 2)}. However, this can
              vary depending on factors such as property type, location, and
              amenities.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              🌟 Is {data?.market} a good place for investing in Airbnb Rentals?
            </Header>
            <Text>
              {data?.market} shows promising potential for Airbnb investments.
              With an average occupancy rate of{" "}
              {percentNotation(data?.occupancy_rate.market / 100)} and a gross
              yield of {data?.gross_yield}, it ranks{" "}
              {data?.us_gross_yield_ranking} among U.S. markets for short-term
              rental investments.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              📈 How profitable is Airbnb in {data?.market}?
            </Header>
            <Text>
              Airbnb can be quite profitable in {data?.market}. The average
              daily rate is ${data?.average_daily_rate.market}, and with an
              occupancy rate of{" "}
              {percentNotation(data?.occupancy_rate.market / 100)}, properties
              here have the potential for significant returns.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>📋 Is Airbnb allowed in {data?.market}?</Header>
            <Text>
              The current Airbnb regulation status in {data?.market} is{" "}
              {data?.["airbnb-regulation"]
                .split("-")
                .map((str) => str[0].toUpperCase() + str.slice(1))
                .join(" ")}
              . However, regulations can change, so it&apos;s important to stay
              updated on local laws and ordinances. For more detailed
              information, please check our{" "}
              <a
                href={`/analytics/rental-regulations/${market}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.market} short-term rental regulations page
              </a>
              .
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              📊 What is the occupancy rate in {data?.market}?
            </Header>
            <Text>
              The average occupancy rate for short-term rentals in{" "}
              {data?.market} is{" "}
              {percentNotation(data?.occupancy_rate.market / 100)}. This rate
              can fluctuate based on seasonality and other market factors.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              🏘️ Is {data?.market} Airbnb rental market saturated?
            </Header>
            <Text>
              With {data?.supply.market} active short-term rentals in{" "}
              {data?.market}, the market shows healthy competition. However, the
              strong occupancy rates and revenue potential suggest there&apos;s
              still room for well-managed properties to succeed.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              🏘️ Do I need an Airbnb realtor in {data?.market}?
            </Header>
            <Text>
              While not strictly necessary, working with a realtor who
              specializes in short-term rentals can be highly beneficial in{" "}
              {data?.market}. They can provide valuable insights into the local
              market, help you find properties with the best investment
              potential, and navigate local regulations. To connect with
              experienced Airbnb realtors in {data?.market}, visit our{" "}
              <a
                href={`/realtors/${market}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.market} Airbnb realtor page
              </a>
              .
            </Text>
          </div>
        </FAQSection>
      )}
      <AnalyticsModal />
    </AnalyticsWrapper>
  );
}
